<template>
  <v-menu v-bind="$attrs">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon>
        <v-icon color="white">{{ icon }}</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item dense>
        <v-list-item-icon>
          <v-icon :color="color">{{ icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> {{ name }} </v-list-item-title>
          <v-list-item-subtitle class="text-wrap">
            {{ description }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      client: null,
      uplinkNetworkQuality: 0,
      downlinkNetworkQuality: 0,
    };
  },

  methods: {
    init(client) {
      this.client = client;
      this.client.on("network-quality", (stats) =>
        this.handleChangeNetworkQuality(stats)
      );
    },

    handleChangeNetworkQuality(stats) {
      this.uplinkNetworkQuality = stats.uplinkNetworkQuality;
      this.downlinkNetworkQuality = stats.downlinkNetworkQuality;
    },
  },

  computed: {
    worstIndex() {
      if (this.downlinkNetworkQuality > this.uplinkNetworkQuality) {
        return this.downlinkNetworkQuality;
      }

      return this.uplinkNetworkQuality;
    },
    name() {
      switch (this.worstIndex) {
        case 0:
          return "Calculando...";
        case 1:
          return "Excelente";
        case 2:
          return "Bom";
        case 3:
          return "Médio";
        case 4:
          return "Ruim";
        case 5:
          return "Péssimo";
        case 6:
          return "Sem internet";
        default:
          return "";
      }
    },
    description() {
      switch (this.worstIndex) {
        case 0:
          return "Calculando qualidade da rede";
        case 1:
          return "A qualidade da rede é excelente";
        case 2:
          return "A qualidade da rede é boa, mas a taxa de bits é inferior à ideal";
        case 3:
          return "Os usuários experimentam uma comunicação ligeiramente prejudicada";
        case 4:
          return "Os usuários podem se comunicar uns com os outros, mas não muito suavemente";
        case 5:
          return "A qualidade é tão ruim que os usuários mal conseguem se comunicar";
        case 6:
          return "A rede está desconectada e os usuários não podem se comunicar";
        default:
          return "";
      }
    },
    color() {
      switch (this.worstIndex) {
        case 0:
          return "";
        case 1:
          return "green";
        case 2:
          return "green";
        case 3:
          return "orange";
        case 4:
          return "red";
        case 5:
          return "red";
        case 6:
          return "red";
        default:
          return "";
      }
    },
    icon() {
      if (this.worstIndex === 0) {
        return "mdi-wifi-settings";
      }

      if (this.worstIndex > 0 && this.worstIndex < 3) {
        return "mdi-wifi-check";
      }

      if (this.worstIndex > 2 && this.worstIndex < 5) {
        return "mdi-wifi-alert";
      }

      if (this.worstIndex === 5) {
        return "mdi-wifi-remove";
      }

      if (this.worstIndex === 6) {
        return "mdi-wifi-off";
      }

      return "mdi-wifi-settings";
    },
  },
};
</script>
