<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-on="on" v-bind="attrs" link class="primary--text">
        <v-list-item-content>
          <v-list-item-title>
            <v-icon color="primary" left>{{ icon }}</v-icon>
            Ajustar microfone
          </v-list-item-title>
          <v-list-item-subtitle class="pl-9">
            {{ currentDeviceId }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-list>
      <v-subheader> MICROFONES </v-subheader>
      <v-list-item
        @click="handleChangeMic(microphone)"
        v-for="microphone in uniqueMicrophones"
        :key="microphone.deviceId"
        :class="{
          'primary--text': currentDeviceId === microphone.label,
        }"
      >
        <v-list-item-content>
          <v-list-item-title>
            <v-icon
              left
              :color="currentDeviceId === microphone.label ? 'primary' : ''"
              >mdi-microphone</v-icon
            >
            {{ microphone.label }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import AgoraRTC from "agora-rtc-sdk-ng";

export default {
  props: {
    localAudioTrack: Object,
  },

  data() {
    return {
      microphones: [],
    };
  },

  computed: {
    icon() {
      if (this.muted) {
        return "mdi-microphone-off";
      }

      return "mdi-microphone-outline";
    },

    muted() {
      if (!this.localAudioTrack) {
        return false;
      }

      return this.localAudioTrack.muted;
    },

    currentDeviceId() {
      if (!this.localAudioTrack) {
        return null;
      }

      return this.localAudioTrack.getTrackLabel();
    },

    uniqueMicrophones() {
      const mics = [];

      this.microphones.forEach((mic) => {
        if (!mics.find((value) => value.groupId === mic.groupId)) {
          mics.push(mic);
        }
      });

      return mics;
    },
  },

  methods: {
    async getMicrophones() {
      this.microphones = await AgoraRTC.getMicrophones();
    },
    handleChangeMic(mic) {
      this.$emit("change", mic);
    },
  },

  created() {
    this.getMicrophones();
  },
};
</script>
