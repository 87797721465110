<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-on="on" v-bind="attrs" link class="primary--text">
        <v-list-item-content>
          <v-list-item-title>
            <v-icon color="primary" left> mdi-camera-outline </v-icon>
            Ajustar câmera
          </v-list-item-title>
          <v-list-item-subtitle class="pl-9">
            {{ currentDeviceId }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-list>
      <v-subheader>CAMERAS</v-subheader>
      <v-list-item
        @click="handleChangeCamera(camera)"
        v-for="camera in cameras"
        :key="camera.deviceId"
        :class="{
          'primary--text': camera.label === currentDeviceId,
        }"
      >
        <v-list-item-content>
          <v-list-item-title>
            <v-icon
              :color="camera.label === currentDeviceId ? 'primary' : ''"
              left
              >mdi-camera</v-icon
            >
            {{ camera.label }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import AgoraRTC from "agora-rtc-sdk-ng";

export default {
  props: {
    localVideoTrack: Object,
  },

  data() {
    return {
      cameras: [],
    };
  },

  methods: {
    async getCameras() {
      this.cameras = await AgoraRTC.getCameras();
    },

    handleChangeCamera(camera) {
      this.$emit("change", camera);
    },
  },

  computed: {
    currentDeviceId() {
      if (!this.localVideoTrack) {
        return null;
      }

      return this.localVideoTrack.getTrackLabel();
    },
  },

  created() {
    this.getCameras();
  },
};
</script>
