<style lang="scss">
.local-video-player {
  width: 100%;
  height: 170px;
  margin-top: 0px;
}

.external-player {
  width: 100%;
  height: 100%;
  margin-top: 0px;
}

.username-chip {
  position: absolute;
  top: 135px;
  left: 10px;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

#enter-button {
  position: fixed;
  bottom: 10px;
  width: 90vw;
  margin-left: 5vw;
}

#container {
  max-width: 400px;
}

#main-player {
  width: 100vw;
  height: calc(80vh);
}

#secondary-player {
  width: 100vw;
  height: 20vh;
}

body {
  overflow-y: hidden !important;
}
</style>

<template>
  <v-app id="app">
    <v-main v-show="!joined && !ended">
      <v-container id="container">
        <v-row>
          <v-col>
            <v-card flat>
              <div id="local-video-player" class="local-video-player"></div>
              <v-card-text>
                Por favor ajuste as configurações de áudio e vídeo até que seja
                possível visualizar a camera traseira do vosso dispositivo
              </v-card-text>
              <v-list>
                <CameraPicker
                  :localVideoTrack="localVideoTrack"
                  @change="changeCamera"
                />
                <MicrophonePicker
                  :localAudioTrack="localAudioTrack"
                  @change="changeMicrophone"
                />
              </v-list>
            </v-card>
          </v-col>
        </v-row>

        <v-checkbox v-model="terms_accepted" v-if="call.can_join" class="mx-4">
          <template v-slot:label>
            Li e aceito os
            <a
              href="/termos_peritagem_remota.htm"
              target="_blank"
              @click.stop
              class="ml-2"
            >
              termos e condições
            </a>
            .
          </template>
        </v-checkbox>

        <v-alert :value="!call.can_join" type="warning">
          {{ call.cant_join_motive }}
        </v-alert>

        <v-btn
          @click="callPerit()"
          color="primary"
          large
          block
          class="mt-10"
          :loading="joining"
          :disabled="!call.can_join || !terms_accepted"
          v-if="call && !call.started_at"
        >
          <v-icon left>mdi-phone</v-icon> Ligar para o perito
        </v-btn>
        <v-btn
          @click="join()"
          color="primary"
          large
          block
          class="mt-10"
          :loading="joining"
          :disabled="!call.can_join || !terms_accepted"
          v-else-if="call && call.started_at"
        >
          <v-icon left>mdi-location-enter</v-icon> Entrar
        </v-btn>

        <p v-if="phone_number" class="mt-5 grey--text">
          Em caso de problema técnico ou dúvida, por favor contactar o nº <a :href="`tel:${phone_number}`">{{ phone_number }}</a>.
        </p>
      </v-container>
    </v-main>

    <v-main v-show="joined" :class="{ 'pt-9': !participantIn }">
      <v-app-bar dark color="info" v-if="!participantIn" fixed>
        <v-app-bar-title> A aguardar perito ... </v-app-bar-title>
        <v-spacer> </v-spacer>
        <v-icon>mdi-clock-outline</v-icon>
      </v-app-bar>
      <div id="secondary-player" v-show="participantIn"></div>
      <div
        id="main-player"
        :style="{
          height: participantIn ? 'calc(80vh - 56px)' : 'calc(100vh - 90px)',
        }"
      ></div>
      <v-bottom-navigation
        color="primary"
        :value="navigation"
        @update:input-value="navigation = 1"
        dark
        disabled
        active-class="grey--text"
        :input-value="true"
        class="bottom-navigation"
        app
      >
        <NetworkStatusButton ref="networkStatusButton" offset-y top left dark />

        <v-btn @click="toggleMute" v-if="!localMicMuted" value="b">
          <span>Mute</span>
          <v-icon>mdi-microphone</v-icon>
        </v-btn>

        <v-btn @click="toggleMute" v-else value="b">
          <span>Unmute</span>
          <v-icon>mdi-microphone-off</v-icon>
        </v-btn>

        <v-btn @click="handleLeave()" class="red--text" value="b">
          <span>Encerrar</span>

          <v-icon>mdi-phone-hangup</v-icon>
        </v-btn>

        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <span>Opçoes</span>

              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>

          <v-list dark>
            <v-subheader>OPÇÕES</v-subheader>
            <CameraPicker
              :localVideoTrack="localVideoTrack"
              @change="changeCamera"
            />
            <MicrophonePicker
              :localAudioTrack="localAudioTrack"
              @change="changeMicrophone"
            />
          </v-list>
        </v-menu>
      </v-bottom-navigation>
    </v-main>

    <v-main v-show="!joined && ended">
      <div
        style="height: 100vh; width: 100vw; background: #333"
        class="d-flex justify-center align-center white--text"
      >
        <div>
          <div class="text-center">
            <v-icon size="60" color="white">mdi-phone-hangup</v-icon>
          </div>
          <br />
          <div class="h2">Chamada encerrada</div>
          <br />
        </div>
      </div>
    </v-main>

    <v-dialog v-model="calling" width="370" persistent eager>
      <v-card>
        <div class="text-center title pt-5">Ligando para o perito</div>

        <v-img
          src="@/assets/phone_calling.gif"
          width="300"
          class="mx-auto"
        ></v-img>
        <v-card-text class="text-center">
          Por favor aguarde, você será redirecionado para a chamada
          automaticamente quando o perito atender
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="cancelCalling()"
            color="grey"
            text
            dark
            class="mx-auto mb-5"
            :loading="canceling"
          >
            <v-icon left>mdi-phone-hangup</v-icon>
            Desligar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { show, joinGuest } from "@/services/video_calls/video_calls";
import {
  store as storeAttempt,
  cancel as cancelAttempt,
} from "@/services/video_calls/video_call_attempts";
import AgoraRTC from "agora-rtc-sdk-ng";
const AGORA_APP_ID =
  /*process.env.AGORA_API_ID*/ "82ead256d1474b709ffa59d16284afc7";

import MicrophonePicker from "@/components/video_calls/MicrophonePicker.vue";
import CameraPicker from "@/components/video_calls/CameraPicker.vue";
import NetworkStatusButton from "@/components/video_calls/NetworkStatusButton";

export default {
  props: {
    call: Object,
    phone_number: String
  },
  components: {
    MicrophonePicker,
    CameraPicker,
    NetworkStatusButton,
  },
  data() {
    return {
      client: null,

      localVideoTrack: null,
      localAudioTrack: null,

      localVideoMuted: false,
      localMicMuted: false,

      audioTest: 0,

      localVideoFit: "cover",

      joined: false,
      joining: false,

      leaving: false,

      navigation: 1,

      participant: null,

      ended: false,

      calling: false,

      attempt: null,

      canceling: false,

      terms_accepted: false,
    };
  },

  methods: {
    async cancelCalling() {
      this.canceling = true;

      try {
        await cancelAttempt(this.attempt.id);
        Echo.leave(`video-call-attempt.${this.attempt.id}`);
        this.calling = false;
        this.attempt = null;
      } finally {
        this.canceling = false;
      }
    },
    async callPerit() {
      this.calling = true;
      try {
        const { data } = await storeAttempt({
          video_call_key: this.call.key,
        });
        this.attempt = data;
        Echo.channel(`video-call-attempt.${data.id}`)
          .listen("VideoCalls\\VideoCallAnsweredEvent", (e) => {
            this.calling = false;
            Echo.leave(`video-call-attempt.${this.attempt.id}`);
            this.join();
          })
          .listen("VideoCalls\\AttemptRefusedEvent", (e) => {
            this.calling = false;
            Echo.leave(`video-call-attempt.${this.attempt.id}`);
            alert("Ocupado, por favor retorne em alguns minutos");
          });
      } catch (error) {
        console.error(error);
        this.calling = false;
      }
    },
    toggleMute() {
      if (this.localAudioTrack.muted) {
        this.localAudioTrack.setMuted(false);
        this.localMicMuted = false;
        return;
      }

      this.localAudioTrack.setMuted(true);
      this.localMicMuted = true;
      return;
    },
    async getCall() {
      if (this.call.started_at && !this.call.finished_at) {
        this.join();
      }
    },
    changeCamera(device) {
      this.localVideoTrack.setDevice(device.deviceId);
    },
    changeMicrophone(device) {
      this.localAudioTrack.setDevice(device.deviceId);
    },
    async handleUserPublished(user, mediaType) {
      await this.client.subscribe(user, mediaType);

      if (mediaType === "video") {
        if (this.participant) {
          this.participant.videoTrack.stop();
        }

        user.videoTrack.play(this.getSecondaryPlayer(), {
          fit: "contain",
        });
      }
      if (mediaType === "audio") {
        if (this.participant) {
          this.participant.audioTrack.stop();
        }

        user.audioTrack.play();
      }

      this.participant = user;
    },
    async handleUserUnpublished(user, mediaType) {
      if (mediaType === "video") {
        if (this.participant) {
          this.participant.videoTrack.stop();
        }
      }
      if (mediaType === "audio") {
        if (this.participant) {
          this.participant.audioTrack.stop();
        }
      }
    },
    async handleUserLeft(user, reason) {
      this.participant = null;
    },
    async turnOnLocalTrack() {
      this.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      this.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
        optimizationMode: "motion",
        facingMode: "environment",
      });
      this.localVideoTrack.play(this.getLocalPlayer(), {
        fit: this.localVideoFit,
        mirror: false,
      });
      this.localVideoMuted = this.localVideoTrack.muted;
      this.localMicMuted = this.localAudioTrack.muted;
    },
    async turnOffLocalTrack() {
      if (this.localAudioTrack) {
        this.localAudioTrack.close();
        this.localAudioTrack = null;
      }

      if (this.localVideoTrack) {
        this.localVideoTrack.close();
        this.localVideoTrack = null;
      }
    },
    getLocalPlayer() {
      return document.querySelector("#local-video-player");
    },
    getMainPlayer() {
      return document.querySelector("#main-player");
    },

    getSecondaryPlayer() {
      return document.querySelector("#secondary-player");
    },
    async join() {
      this.joining = true;

      try {
        const { token } = await joinGuest(this.$route.params.key);
        this.client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });
        await this.client.join(AGORA_APP_ID, this.$route.params.key, token);

        Echo.channel(`video-calls.${this.$route.params.key}`).listen(
          "VideoCalls\\VideoCallEndedEvent",
          (e) => {
            console.log("Chamada finalizada");
            this.handleCallEnded();
          }
        );

        this.client.on("user-published", (user, mediaType) =>
          this.handleUserPublished(user, mediaType)
        );

        this.client.on("user-unpublished", (user, mediaType) =>
          this.handleUserUnpublished(user, mediaType)
        );

        this.client.on("user-left", (user, reason) =>
          this.handleUserLeft(user, reason)
        );

        this.joined = true;

        await this.localVideoTrack.stop();
        this.localVideoTrack.play(this.getMainPlayer(), {
          fit: this.localVideoFit,
          mirror: false,
        });

        this.client.publish(this.localVideoTrack);
        this.client.publish(this.localAudioTrack);

        this.$refs.networkStatusButton.init(this.client);
      } finally {
        this.joining = false;
      }
    },
    async handleCallEnded() {
      await this.localVideoTrack.stop();
      this.localVideoTrack.play(this.getLocalPlayer(), {
        fit: this.localVideoFit,
        mirror: false,
      });
      this.joined = false;
      this.participant = null;
      this.ended = true;
      this.turnOffLocalTrack();
    },
    async handleLeave() {
      this.leaving = true;

      try {
        await this.client.leave();
        await this.localVideoTrack.stop();
        this.localVideoTrack.play(this.getLocalPlayer(), {
          fit: this.localVideoFit,
          mirror: false,
        });
        this.joined = false;
        this.participant = null;
        this.ended = true;
        this.turnOffLocalTrack();
      } finally {
        this.leaving = false;
      }
    },
  },

  computed: {
    participantIn() {
      return this.participant !== null;
    },
  },

  async created() {
    await this.turnOnLocalTrack();
    await this.getCall();
  },

  beforeDestroy() {
    this.client.leave();

    if (this.calling) {
      this.cancelCalling();
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const { data, meta } = await show(to.params.key);

      next(vm => {
        vm.call = data;
        vm.phone_number = meta.hcm_number
      })
    } catch(error) {
      if(error.response?.status === 404) {
        next({
          name: 'video_call_not_found'
        })
      } else {
        next({
          name: 'video_call_error',
          query: {
            from: to.params.key
          }
        })
      }
    }
  }
};
</script>
